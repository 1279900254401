body {
    background-color: #d3e0ea; 
  }
  
  .container {
    background-color: #f4dcc8; 
  }
  
  .card {
    background-color: #f2f2f2; 
  }